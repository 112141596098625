import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/preview-react/radio/Radio.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const NoticeCard = makeShortcode("NoticeCard");
const InternalContent = makeShortcode("InternalContent");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/preview-react/radio" storybook="https://workday.github.io/canvas-kit/?path=/story/preview-inputs-radio--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=20281-44959&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-preview-react" mdxType="PackageInfo" />
      <NoticeCard headingText="Radio (Main) vs. Radio (Preview)" linkText="Radio (Main) Documentation" linkTo="https://workday.github.io/canvas-kit/?path=/docs/components-inputs-radio--basic" mdxType="NoticeCard">
        <p>{`We recommend you use the Radio component in the Preview package
(`}<inlineCode parentName="p">{`@workday/canvas-kit-preview-react`}</inlineCode>{`) documented here on this page. The Radio component in the Main
package (`}<inlineCode parentName="p">{`@workday/canvas-kit-react`}</inlineCode>{`) will eventually be replaced with the Radio component in the
Preview package.`}</p>
      </NoticeCard>
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/eb4a46f4a44f7fa2faabe349e0a2dd99/536c7/component-anatomy-radio-group.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "27.027027027027025%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Radio Group in its default state.",
              "title": "Image of a Radio Group in its default state.",
              "src": "/static/eb4a46f4a44f7fa2faabe349e0a2dd99/50383/component-anatomy-radio-group.png",
              "srcSet": ["/static/eb4a46f4a44f7fa2faabe349e0a2dd99/1efb2/component-anatomy-radio-group.png 370w", "/static/eb4a46f4a44f7fa2faabe349e0a2dd99/50383/component-anatomy-radio-group.png 740w", "/static/eb4a46f4a44f7fa2faabe349e0a2dd99/536c7/component-anatomy-radio-group.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Form Field Label:`}</strong>{` The Form Field Label describes all of the Radio in the Radio group and
functions as a header.`}</li>
        <li parentName="ol"><strong parentName="li">{`Radio Button:`}</strong>{` Radio Buttons are vertically aligned and in close proximity to its own label.`}</li>
        <li parentName="ol"><strong parentName="li">{`Radio Label:`}</strong>{` Radio Labels give information about what to select.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`The Form Field Label can be positioned in two places; above or left of Radio group for LTR
languages. Form Field Labels are aligned to the right of Radio group for RTL languages.`}</li>
        <li parentName="ul">{`Radio Labels are positioned to the right of Radio Buttons for LTR languages or to the left of
Radio Buttons for RTL languages.`}</li>
        <li parentName="ul">{`You can configure one Radio Button in the group preselected by default. This default option should
be the safest option presenting the least risk to the user, or be the option most commonly
selected.`}</li>
        <li parentName="ul">{`If the user is allowed to not select any of the options, provide a “None” option.`}</li>
        <li parentName="ul">{`If the list doesn't encompass all possible values in 7 or less options, provide a Radio Button
marked “Other.”`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use Radio Buttons when a user is only allowed to select 1 value from a list of 7 or less options.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Consider using a `}<a parentName="li" {...{
            "href": "/components/inputs/switch"
          }}>{`Switch`}</a>{` if the only options are yes or no.`}</li>
        <li parentName="ul">{`If a user is allowed to select 0, 1, or multiple values from a predefined list of 7 or less
options, consider using `}<a parentName="li" {...{
            "href": "/components/inputs/checkbox"
          }}>{`Checkboxes`}</a>{` or a
`}<a parentName="li" {...{
            "href": "/components/inputs/select"
          }}>{`Select`}</a>{` to select one option.`}</li>
        <li parentName="ul">{`Use a Prompt when the number of list items is large or unknown. Prompts have search capabilities
and folders which provide users with the means to browse options. Prompts can be configured to
support single or multi-select.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "how-radio-buttons-impact-the-accessible-experience"
      }}>{`How Radio Buttons Impact the Accessible Experience`}</h3>
      <p>{`While associating labels to each radio button input is critical, radio buttons are used in groups of
mutually exclusive choices. A group of choices requires context, such as, selecting your pizza
crust. Designing a clear legend and building the context of the group is a key foundation for
accessible radio buttons when users cannot visually perceive the layout.`}</p>
      <NoticeCard headingText="Accessibility of Disabled Radio Buttons" linkText="Disabled Radio Example" linkTo="/components/inputs/radio#tab=web&heading=disabled" mdxType="NoticeCard">
        <p>{`Disabled radio buttons will not be accessible with the keyboard. While this is very standard,
pre-selecting the disabled radio button will block keyboard access to the entire group of radio
buttons. Furthermore, screen reader software will describe the total number of radio buttons in the
group regardless of the disabled state.`}</p>
      </NoticeCard>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each Radio Button must have a focus indicator that is highly visible against the background and
against the non-focused state. Refer to `}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for
more information.`}</p>
      <p>{`Radio Buttons must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus the group of radio buttons`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Space`}</inlineCode>{`: select focused radio button`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Down Arrow`}</inlineCode>{` or `}<inlineCode parentName="li">{`Right Arrow`}</inlineCode>{`: select the next radio button`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Up Arrow`}</inlineCode>{` or `}<inlineCode parentName="li">{`Left Arrow`}</inlineCode>{`: select the previous radio button`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Radio Buttons must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`The name (Form Field Label) of the radio button grouping`}</li>
        <li parentName="ul">{`The accessible name (label) of the focused radio button`}</li>
        <li parentName="ul">{`Which Radio Button in the group is selected`}</li>
        <li parentName="ul">{`The total number of radio buttons in the grouping`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`Write the context (legend) of the radio button grouping.`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`Radio buttons must have a `}<inlineCode parentName="li">{`required`}</inlineCode>{` attribute when the Radio Group is required for submission.`}</li>
        <li parentName="ul">{`Radio buttons must have an `}<inlineCode parentName="li">{`aria-invalid=”true”`}</inlineCode>{` attribute when the Radio Group has an error
condition.`}</li>
        <li parentName="ul">{`[Included in component]`}{` A `}<inlineCode parentName="li">{`<label>`}</inlineCode>{` element is required with a `}<inlineCode parentName="li">{`for`}</inlineCode>{` attribute referencing the
unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` value of the associated radio button.`}</li>
        <li parentName="ul">{`[Included in component]`}{` A `}<inlineCode parentName="li">{`<fieldset>`}</inlineCode>{` element establishes the group of related Radio Buttons. The
child `}<inlineCode parentName="li">{`<legend>`}</inlineCode>{` element describes the context of the grouping.`}</li>
        <li parentName="ul">{`[Included in component]`}{` A `}<inlineCode parentName="li">{`disabled`}</inlineCode>{` attribute is set when the radio button is disabled.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`Form Field Labels are written in title case.`}</li>
        <li parentName="ul">{`The Radio Label for each individual selection are kept as concise as possible and written in
sentence case.`}</li>
        <li parentName="ul">{`When writing Radio Button labels, refer to the `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/radio-buttons"
          }}>{`Radio Buttons`}</a>{`
section of the Content Style Guide.`}</li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=20%3A4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ac0ac4b145bc8b68ef2dbb9f7e53a6b1/536c7/component-anatomy-radio-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Radio Button's anatomy",
                "title": "Image of a Radio Button's anatomy",
                "src": "/static/ac0ac4b145bc8b68ef2dbb9f7e53a6b1/50383/component-anatomy-radio-mobile.png",
                "srcSet": ["/static/ac0ac4b145bc8b68ef2dbb9f7e53a6b1/1efb2/component-anatomy-radio-mobile.png 370w", "/static/ac0ac4b145bc8b68ef2dbb9f7e53a6b1/50383/component-anatomy-radio-mobile.png 740w", "/static/ac0ac4b145bc8b68ef2dbb9f7e53a6b1/536c7/component-anatomy-radio-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Container`}</strong>{`: Main container of the Radio Button.`}</li>
          <li parentName="ol"><strong parentName="li">{`Dot`}</strong>{`: Circular shape used to indicate if the item is selected or unselected.`}</li>
          <li parentName="ol"><strong parentName="li">{`Radio Label`}</strong>{`: Radio Labels give information about what to select.`}</li>
        </ol>
        <h2 {...{
          "id": "interaction-states"
        }}>{`Interaction States`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/905d9cead4fd54fd213c85886dc7c407/536c7/component-states-radio-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Radio Button's anatomy",
                "title": "Image of a Radio Button's anatomy",
                "src": "/static/905d9cead4fd54fd213c85886dc7c407/50383/component-states-radio-mobile.png",
                "srcSet": ["/static/905d9cead4fd54fd213c85886dc7c407/1efb2/component-states-radio-mobile.png 370w", "/static/905d9cead4fd54fd213c85886dc7c407/50383/component-states-radio-mobile.png 740w", "/static/905d9cead4fd54fd213c85886dc7c407/536c7/component-states-radio-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ul>
          <li parentName="ul">{`Mobile Radio Buttons support Inactive, Pressed, Selected, and Disabled states.`}</li>
        </ul>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`Radio Labels are positioned to the right of Radio Buttons for LTR languages or to the left of
Radio Buttons for RTL languages.`}</li>
          <li parentName="ul">{`You can configure one Radio Button in a list to be pre-selected by default. This default option
should be the safest option presenting the least risk to the user.`}</li>
          <li parentName="ul">{`Alternatively, this default can be the option most commonly selected.`}</li>
          <li parentName="ul">{`If the user is allowed to not select any of the options, provide a “None”,“Not Applicable”, or “I
prefer not to answer” option.`}</li>
          <li parentName="ul">{`If the list doesn’t encompass all possible values in 7 or less options, provide a Radio Button
marked “Other”.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-1"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`Use Radio Buttons when a user is only allowed to select 1 value from a list of 7 or less options.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-1"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`Consider using a Switch for binary options such as yes/no or active/disabled.`}</li>
          <li parentName="ul">{`If a user is allowed to select 0, 1, or multiple values from a predefined list of 7 or less
options, consider using `}<a parentName="li" {...{
              "href": "/components/inputs/checkbox#tab=ios"
            }}>{`Checkboxes`}</a>{` or a Select to select
one option.`}</li>
          <li parentName="ul">{`Use a Prompt when the number of list items exceeds 8+ options or is unknown. Prompts have search
capabilities and folders which provide users with the means to browse options. Prompts can be
configured to support single or multi-select.`}</li>
        </ul>
        <h3 {...{
          "id": "mobile-guidance"
        }}>{`Mobile Guidance`}</h3>
        <ul>
          <li parentName="ul">{`Ensure a minimum tap target of 48dp to ensure that they are easy to interact with. For Radio
Buttons, an additional 12dp of tappable horizontal and vertical padding are added to meet the
minimum touch target.`}</li>
        </ul>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Use a minimum tap target of 48dp to ensure the Radio button is easy to interact with." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/decf8be6ec9e7eed8115a4fb4a12cdbb/3cb0f/component-do-taptarget-radio-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Radio Button with a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Radio Button with a minimum tap target of 48dp.",
                    "src": "/static/decf8be6ec9e7eed8115a4fb4a12cdbb/3cb0f/component-do-taptarget-radio-mobile.png",
                    "srcSet": ["/static/decf8be6ec9e7eed8115a4fb4a12cdbb/1efb2/component-do-taptarget-radio-mobile.png 370w", "/static/decf8be6ec9e7eed8115a4fb4a12cdbb/3cb0f/component-do-taptarget-radio-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Use a tap target height smaller than 48dp, even if the Radio button might appear smaller visually." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/0990ef20c41958693812d1eb9d421f06/3cb0f/component-dont-taptarget-radio-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Radio Button without a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Radio Button without a minimum tap target of 48dp.",
                    "src": "/static/0990ef20c41958693812d1eb9d421f06/3cb0f/component-dont-taptarget-radio-mobile.png",
                    "srcSet": ["/static/0990ef20c41958693812d1eb9d421f06/1efb2/component-dont-taptarget-radio-mobile.png 370w", "/static/0990ef20c41958693812d1eb9d421f06/3cb0f/component-dont-taptarget-radio-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition"
        }}>{`Component Definition`}</h3>
        <p><inlineCode parentName="p">{`RadioButton`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public struct RadioButton: View
`}</code></pre>
        <p>{`WD-Standard Radio Button component, a custom SwiftUI view.`}</p>
        <h3 {...{
          "id": "properties"
        }}>{`Properties`}</h3>
        <p><inlineCode parentName="p">{`body`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public var body: some View
`}</code></pre>
        <h3 {...{
          "id": "methods"
        }}>{`Methods`}</h3>
        <p><inlineCode parentName="p">{`init(isSelected:context:accessibilityLabel:content:)`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public init<Content: View>(
    isSelected: Binding<Bool>,
    context: SemanticContext = .default,
    accessibilityLabel: String = "",
    @ViewBuilder content: @escaping () -> Content
)
`}</code></pre>
        <p>{`Creates an instance of Radio Button.`}</p>
        <h4 {...{
          "id": "parameters"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isSelected`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Whether or not the radio button is selected by default.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`context`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`SemanticContext`}</inlineCode>{` of the field.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`accessibilityLabel`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`accessibility label of the button, already localized.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`content`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The view content to be displayed to the right of the button.`}</td>
            </tr>
          </tbody>
        </table>
        <p><inlineCode parentName="p">{`init(isSelected:context:accessibilityText:localizer:content:)`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public init<Content: View>(
    isSelected: Binding<Bool>,
    context: SemanticContext = .default,
    accessibilityText: String = "",
    localizer: LocalizationAdapting,
    @ViewBuilder content: @escaping () -> Content
)
`}</code></pre>
        <p>{`Creates an instance of Radio Button.`}</p>
        <h4 {...{
          "id": "parameters-1"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isSelected`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Whether or not the radio button is selected by default.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`context`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`SemanticContext`}</inlineCode>{` of the field.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`accessibilityText`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`accessibility text of the button content.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`localizer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`localizer used for localizing the radio button’s accessibility info.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`content`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The view content to be displayed to the right of the button.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-1"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`The Radio Label for each individual selection is kept as concise as possible, and written in
sentence case.`}</li>
          <li parentName="ul">{`When writing `}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/radio-buttons"
            }}>{`Radio Button`}</a>{` labels, refer to the Radio Buttons
section of the Content Style Guide.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/DbZ2rwYQXibXrcbrm1rzJZ/Canvas-Web-Internal-V10?type=design&node-id=20281%3A44959&mode=design&t=4ZXjDl1VnqFTeuwE-1" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ac0ac4b145bc8b68ef2dbb9f7e53a6b1/536c7/component-anatomy-radio-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Radio Button's anatomy",
                "title": "Image of a Radio Button's anatomy",
                "src": "/static/ac0ac4b145bc8b68ef2dbb9f7e53a6b1/50383/component-anatomy-radio-mobile.png",
                "srcSet": ["/static/ac0ac4b145bc8b68ef2dbb9f7e53a6b1/1efb2/component-anatomy-radio-mobile.png 370w", "/static/ac0ac4b145bc8b68ef2dbb9f7e53a6b1/50383/component-anatomy-radio-mobile.png 740w", "/static/ac0ac4b145bc8b68ef2dbb9f7e53a6b1/536c7/component-anatomy-radio-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Container`}</strong>{`: Main container of the Radio Button.`}</li>
          <li parentName="ol"><strong parentName="li">{`Dot`}</strong>{`: Circular shape used to indicate if the item is selected or unselected.`}</li>
          <li parentName="ol"><strong parentName="li">{`Radio Label`}</strong>{`: Radio Labels give information about what to select.`}</li>
        </ol>
        <h2 {...{
          "id": "interaction-states-1"
        }}>{`Interaction States`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/905d9cead4fd54fd213c85886dc7c407/536c7/component-states-radio-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Radio Button's anatomy",
                "title": "Image of a Radio Button's anatomy",
                "src": "/static/905d9cead4fd54fd213c85886dc7c407/50383/component-states-radio-mobile.png",
                "srcSet": ["/static/905d9cead4fd54fd213c85886dc7c407/1efb2/component-states-radio-mobile.png 370w", "/static/905d9cead4fd54fd213c85886dc7c407/50383/component-states-radio-mobile.png 740w", "/static/905d9cead4fd54fd213c85886dc7c407/536c7/component-states-radio-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ul>
          <li parentName="ul">{`Mobile Radio Buttons support Inactive, Pressed, Selected, and Disabled states.`}</li>
        </ul>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`Radio Labels are positioned to the right of Radio Buttons for LTR languages or to the left of
Radio Buttons for RTL languages.`}</li>
          <li parentName="ul">{`You can configure one Radio Button in a list to be pre-selected by default. This default option
should be the safest option presenting the least risk to the user.`}</li>
          <li parentName="ul">{`Alternatively, this default can be the option most commonly selected.`}</li>
          <li parentName="ul">{`If the user is allowed to not select any of the options, provide a “None”,“Not Applicable”, or “I
prefer not to answer” option.`}</li>
          <li parentName="ul">{`If the list doesn’t encompass all possible values in 7 or less options, provide a Radio Button
marked “Other”.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-2"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`Use Radio Buttons when a user is only allowed to select 1 value from a list of 7 or less options.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-2"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`Consider using a Switch for binary options such as yes/no or active/disabled.`}</li>
          <li parentName="ul">{`If a user is allowed to select 0, 1, or multiple values from a predefined list of 7 or less
options, consider using `}<a parentName="li" {...{
              "href": "/components/inputs/checkbox#tab=android"
            }}>{`Checkboxes`}</a>{` or a Select to
select one option.`}</li>
          <li parentName="ul">{`Use a Prompt when the number of list items exceeds 8+ options or is unknown. Prompts have search
capabilities and folders which provide users with the means to browse options. Prompts can be
configured to support single or multi-select.`}</li>
        </ul>
        <h3 {...{
          "id": "mobile-guidance-1"
        }}>{`Mobile Guidance`}</h3>
        <ul>
          <li parentName="ul">{`Ensure a minimum tap target of 48dp to ensure that they are easy to interact with. For Radio
Buttons, an additional 12dp of tappable horizontal and vertical padding are added to meet the
minimum touch target.`}</li>
        </ul>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Use a minimum tap target of 48dp to ensure the Radio button is easy to interact with." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/decf8be6ec9e7eed8115a4fb4a12cdbb/3cb0f/component-do-taptarget-radio-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Radio Button with a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Radio Button with a minimum tap target of 48dp.",
                    "src": "/static/decf8be6ec9e7eed8115a4fb4a12cdbb/3cb0f/component-do-taptarget-radio-mobile.png",
                    "srcSet": ["/static/decf8be6ec9e7eed8115a4fb4a12cdbb/1efb2/component-do-taptarget-radio-mobile.png 370w", "/static/decf8be6ec9e7eed8115a4fb4a12cdbb/3cb0f/component-do-taptarget-radio-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Use a tap target height smaller than 48dp, even if the Radio button might appear smaller visually." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/0990ef20c41958693812d1eb9d421f06/3cb0f/component-dont-taptarget-radio-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Radio Button without a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Radio Button without a minimum tap target of 48dp.",
                    "src": "/static/0990ef20c41958693812d1eb9d421f06/3cb0f/component-dont-taptarget-radio-mobile.png",
                    "srcSet": ["/static/0990ef20c41958693812d1eb9d421f06/1efb2/component-dont-taptarget-radio-mobile.png 370w", "/static/0990ef20c41958693812d1eb9d421f06/3cb0f/component-dont-taptarget-radio-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition-1"
        }}>{`Component Definition`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`@Composable
fun NonInteractivePillUiComponent(
    modifier: Modifier = Modifier,
    selected: Boolean,
    onClick: () -&gt; Unit,
    enabled: Boolean = true,
    interactionSource: MutableInteractionSource = remember { MutableInteractionSource() },
    colors: RadioButtonUiComponentColors = RadioButtonUiComponentDefaults.colors())
) {
`}</code></pre>
        <h3 {...{
          "id": "parameters-2"
        }}>{`Parameters`}</h3>
        <p>{`All undocumented parameters are native Android Compose types that can be found in the developer
documentation.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`modifier`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Modifier to be applied to the RadioButton`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`selected`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`whether this radio button is selected or not.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`onClick`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`callback to be invoked when the RadioButton is clicked. If null, then this RadioButton will not handle input events, and only act as a visual indicator of selected state.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`enabled`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Controls the enabled state of the RadioButton. When false, this button will not be selectable and appears disabled.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`interactionSource`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the MutableInteractionSource representing the stream of Interactions for this RadioButton. You can create and pass in your own remembered MutableInteractionSource if you want to observe Interactions and customize the appearance / behavior of this RadioButton in different Interactions.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`colors`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`RadioButtonUiComponentColors is used to resolve the color used for this RadioButton in different states.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-2"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`The Radio Label for each individual selection is kept as concise as possible, and written in
sentence case.`}</li>
          <li parentName="ul">{`When writing `}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/radio-buttons"
            }}>{`Radio Button`}</a>{` labels, refer to the Radio Buttons
section of the Content Style Guide.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      